<template>
  <div class="relative">
    <div class="flex items-start">
      <button
        class="group mr-30 hidden transition-all duration-300 max-md:absolute max-md:bottom-[-11px] max-md:left-0 max-sm:bottom-[-7px] max-sm:hidden md:mt-[140px]"
        v-if="title === active"
        :class="{
          'sm:!block': dataArr.length > 3,
          'max-sm:!block': dataArr.length > 2,
        }"
        @click="
          () => {
            sl.prev();
          }
        "
      >
        <svg
          width="22"
          height="37"
          viewBox="0 0 16 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="max-sm:h-[27px] max-sm:w-[16px]"
        >
          <g clip-path="url(#clip0_60_93)">
            <path
              d="M13.5193 25.4783L2.48083 13.5L13.5193 1.52168"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="group-hover:stroke-pastelPinkL"
            />
          </g>
          <defs>
            <clipPath id="clip0_60_93">
              <rect
                width="16"
                height="27"
                fill="white"
                transform="translate(16 27) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
      <div
        ref="sl"
        class="keen-slider sl-slider"
        :class="{
          'sm:justify-center': dataArr?.length < 3,
          'max-sm:!overflow-visible': visible,
        }"
      >
        <template v-if="title === active">
          <div
            v-for="item in this.dataArr"
            :key="item.id"
            class="keen-slider__slide group relative mb-10 mt-15 flex flex-col !overflow-visible max-sm:!overflow-visible max-sm:py-20 sm:pb-45"
          >
            <span
              v-if="!item.available"
              class="absolute left-[50%] top-0 z-10 w-full translate-x-[-50%] bg-ss3_light_blue px-10 py-5 text-center text-white"
              >In-Store Only
            </span>
            <span
              v-if="item?.badge"
              class="absolute left-40 top-10 z-10 flex w-[72px] rotate-[-21deg] items-center justify-center overflow-hidden rounded-[48%] bg-ss3_purple px-5 py-[13px] text-center text-sm uppercase text-white max-sm:left-0 max-sm:top-25 max-sm:w-55 max-sm:py-10 max-sm:text-xs"
            >
              {{ item?.badge }}
            </span>

            <a :href="item.url" class="group relative mx-auto mb-20 block pt-5">
              <img
                :src="item.featured_image"
                :alt="item.title"
                class="mx-auto w-[256px] object-cover transition-all duration-500 group-hover:scale-105"
              />
              <img
                v-if="item?.images.length > 0"
                :src="item?.images[1]?.src"
                :alt="item.title"
                class="absolute left-0 top-0 h-full w-full object-cover opacity-0 transition-all duration-500 group-hover:scale-105 group-hover:opacity-100"
              />
              <svg
                v-if="item?.bg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 299 307"
                fill="currentColor"
                class="absolute left-0 top-0 z-[-1] h-full w-full object-contain sm:scale-110"
                :style="`color:${item?.bg}`"
              >
                <path
                  d="M216.504 278.091C222.704 264.713 221.646 251.643 219.893 237.349C219.213 231.808 218.082 226.343 216.691 220.954C228.48 225.892 241.145 227.591 253.984 224.669C273.413 220.247 287.853 205.905 295.166 187.025C304.143 163.849 295.412 138.225 282.966 118.657C282.032 117.191 281.08 115.739 280.126 114.287C285.969 109.026 290.683 102.393 292.801 94.568C295.892 83.1472 296.057 69.9724 290.13 59.4409C279.888 41.249 265.509 24.4124 247.426 14.3896C235.167 7.59767 222.289 1.43364 208.121 0.818098C192.027 0.120237 174.645 8.53147 166.014 23.039C162.006 29.7766 159.528 36.3229 158.062 43.6078C155.677 42.3795 153.258 41.2321 150.779 40.3543C142.129 37.2878 131.969 37.1934 123.054 38.9235C110.518 41.3546 98.1042 50.475 91.3716 61.6436C85.8326 70.8319 83.4705 81.4098 82.9564 92.1274C82.7069 97.332 82.7962 102.581 83.1119 107.828C78.1311 101.926 71.8853 97.1837 64.5238 95.0499C53.7944 91.9426 40.5862 91.4904 30.7797 97.9711C21.9725 103.794 14.7791 109.796 9.18117 119.227C3.81048 128.279 0.533048 139.002 0.275297 149.647C-0.2474 171.263 9.7879 191.33 26.945 203.38C34.995 209.035 43.6743 213.726 51.715 219.45C59.929 225.297 67.9117 231.484 75.7467 237.866C91.5045 250.698 106.638 264.327 121.898 277.79C129.009 284.063 135.992 291.159 143.701 296.622C152.081 302.56 161.927 305.651 171.967 306.337C191.007 307.632 208.424 295.512 216.498 278.09L216.504 278.091ZM77.8763 173.198C77.6751 173.344 77.477 173.488 77.2758 173.634C77.5332 173.42 77.7936 173.206 78.0482 172.989C77.9918 173.06 77.9325 173.13 77.8763 173.198Z"
                  fill="currentColor"
                />
              </svg>
            </a>
            <Calming v-if="item?.calmingbadge">{{
              item?.calmingbadge
            }}</Calming>

            <junip
              :product-id="item?.id"
              :store-key="junipkey"
              :link="item?.url"
            ></junip>
            <a
              :href="item.url"
              class="product-title my-[15px] block font-bionM max-sm:mt-10 max-sm:leading-5 sm:text-[18px] sm:leading-6"
            >
              {{ item?.title }}
            </a>
            <Calming v-if="item?.cbdbadge" :white="true">{{
              item?.cbdbadge
            }}</Calming>
            <span class="mb-20 block flex-auto font-bionM">
              {{ item?.price }}
            </span>
            <form v-if="item.available" id="product-form">
              <AddToCartMisc v-slot="{loading, disabled, addToCart}">
                <button
                  type="submit"
                  @click.prevent="addToCart(item.variants[0].id)"
                  class="btn-block !max-w-[277px] !border-[2px] max-md:h-30"
                >
                  <span v-if="loading">Loading</span>
                  <span v-else>
                    <span class="hidden xs:block">Add To Cart</span>
                    <span class="block xs:hidden">
                      <svg
                        class="svg-icon"
                        style="
                          width: 1em;
                          height: 1em;
                          vertical-align: middle;
                          fill: currentColor;
                          overflow: hidden;
                        "
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M849.066667 349.866667h-115.2c4.266667-25.6 0-51.2-8.533334-81.066667-34.133333-119.466667-157.866667-187.733333-277.333333-157.866667-55.466667 21.333333-102.4 59.733333-132.266667 110.933334-21.333333 38.4-29.866667 85.333333-29.866666 128H174.933333c-17.066667 0-29.866667 12.8-29.866666 34.133333v337.066667c0 115.2 93.866667 213.333333 213.333333 213.333333h200.533333c17.066667 0 34.133333-12.8 34.133334-34.133333s-12.8-34.133333-34.133334-34.133334H354.133333c-81.066667 0-149.333333-68.266667-149.333333-149.333333V413.866667H810.666667v174.933333c0 17.066667 12.8 34.133333 34.133333 34.133333s34.133333-12.8 34.133333-34.133333V384c0-21.333333-12.8-34.133333-29.866666-34.133333z m-179.2 0H354.133333c-4.266667-34.133333 0-68.266667 17.066667-98.133334C392.533333 213.333333 426.666667 187.733333 469.333333 174.933333c85.333333-25.6 174.933333 25.6 196.266667 110.933334 4.266667 21.333333 8.533333 42.666667 4.266667 64z"
                          fill="#080405"
                        />
                        <path
                          d="M849.066667 704h-132.266667v-132.266667c0-17.066667-12.8-29.866667-34.133333-29.866666-17.066667 0-29.866667 12.8-29.866667 34.133333v132.266667h-132.266667c-17.066667 0-29.866667 12.8-29.866666 34.133333 0 17.066667 12.8 29.866667 34.133333 29.866667h132.266667v132.266666c0 17.066667 12.8 29.866667 34.133333 29.866667 17.066667 0 29.866667-12.8 29.866667-34.133333V768H853.333333c17.066667 0 29.866667-12.8 29.866667-34.133333-4.266667-17.066667-17.066667-29.866667-34.133333-29.866667z"
                          fill="#1A1311"
                        />
                      </svg>
                    </span>
                  </span>
                </button>
              </AddToCartMisc>
            </form>
          </div>
        </template>
      </div>
      <button
        class="group ml-30 hidden transition-all duration-300 max-md:absolute max-md:bottom-[-11px] max-md:right-0 max-sm:bottom-[-7px] md:mt-[140px]"
        v-if="title === active"
        :class="{
          'sm:!block': dataArr.length > 3,
          'max-sm:!block': dataArr.length > 2,
        }"
        @click="
          () => {
            sl.next();
          }
        "
      >
        <svg
          width="22"
          height="37"
          viewBox="0 0 16 27"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          class="max-sm:h-[27px] max-sm:w-[16px]"
        >
          <g clip-path="url(#clip0_60_91)">
            <path
              d="M2.48071 1.52173L13.5192 13.5L2.48071 25.4783"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="group-hover:stroke-pastelPinkL"
            />
          </g>
          <defs>
            <clipPath id="clip0_60_91">
              <rect width="16" height="27" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
    <div
      class="flex w-full justify-center"
      :class="{
        'max-sm:min-h-15': dataArr.length > 2 && title === active,
      }"
    >
      <div
        v-if="dataArr.length > 3 && title === active"
        class="dots-gift flex flex-wrap justify-center gap-10"
      >
        <button
          type="button"
          v-for="(_slide, idx) in dotHelper?.slice(0, -2)"
          @click="
            () => {
              sl.moveToIdx(idx);
            }
          "
          :class="{dot: true, active: current === idx}"
          :key="idx"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import KeenSlider from "keen-slider";
import "keen-slider/keen-slider.min.css";
import AddToCart from "../AddToCart";
import junip from "../Junip/index.vue";
import Calming from "../Badgets/Calming.vue";
export default {
  name: "collection-products-find",
  props: {
    data: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      dataArr: JSON.parse(this.data),
      current: 0,
      sl: null,
      junipkey: window.__theme?.junip?.storekey || "CP9UUhju5nUNfaMofet11Bwo",
    };
  },
  components: {
    junip,
    AddToCart,
    Calming,
  },
  computed: {
    ...mapState("bestselers-section", ["active"]),
    dotHelper() {
      return this.sl
        ? [...Array(this.sl?.track?.details?.slides?.length).keys()]
        : [];
    },
  },
  methods: {
    ...mapActions("bestselers-section", ["updateColor"]),
    initSlider() {
      setTimeout(() => {
        this.sl = new KeenSlider(this.$refs.sl, {
          loop: false,
          rtl: false,
          initial: this.current,
          slideChanged: s => {
            this.current = s.track.details.rel;
          },

          breakpoints: {
            "(max-width: 1024px)": {
              slides: {
                perView: 2,
                spacing: 10,
              },
            },
            "(max-width: 600px)": {
              slides: {
                perView: 2.1,
                spacing: 20,
              },
            },
          },
          slides: {
            perView: 3,
            spacing: 40,
          },
        });
      }, 1);
    },
  },
  watch: {
    active() {
      this.initSlider();
      if (this.title === this.active) {
        this.updateColor(this.dataArr[this.index - 1]?.bg);
      }
    },
  },
  mounted() {
    // console.log(this.dataArr);
    this.initSlider();
  },
};
</script>
<style>
.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #3f3f3f;
}

.dot:after {
  display: none !important;
}

.dot.active {
  background-color: #90c540;
  border-color: transparent;
}
</style>
