<template>
  <div class="mb-20 mt-30 max-sm:my-20">
    <span v-if="!objSelected?.compare_at_price" class="mb-15 block text-xl">{{
      objSelected?.price
    }}</span>
    <div class="flex gap-10 font-bionM" v-if="data?.length > 1">
      <button
        class="flex h-[45px] min-w-75 items-center justify-center border-[2px] border-ss3_gray px-5 text-ss3_gray max-sm:h-30"
        :class="{' bg-ss3_gray text-white': variant.id === selectedVariant}"
        v-for="variant in data"
        :key="variant.id"
        @click="onSetVariant(variant.id)"
      >
        {{ variant?.title }}ct
      </button>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "ProductVariants",

  props: {
    variants: {
      type: String,
      default: "",
    },
    current: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: JSON.parse(this.variants),
    };
  },
  computed: {
    ...mapState("product", ["selectedVariant"]),
    objSelected() {
      return this.data.find(item => item.id === this.selectedVariant);
    },
  },
  methods: {
    ...mapActions("product", ["setProduct", "setVariant"]),
    onSetVariant(id) {
      const priceSubSave = document.querySelector("[data-price-subsave]");
      const priceOneTime = document.querySelector("[data-price-onetime]");

      if (priceSubSave && priceOneTime) {
        const obj = this.data.find(item => item.id === id);
        priceSubSave.style.fontSize = "0px";
        priceOneTime.style.fontSize = "0px";
        priceSubSave.setAttribute("data-price", obj?.compare_at_price);
        priceOneTime.setAttribute("data-price", obj?.price);
      }
      this.setVariant(id);
    },
  },

  mounted() {
    this.onSetVariant(this.current);
  },
};
</script>

<style>
.rc_widget__price--subsave::before,
.rc_widget__price--onetime::before {
  content: attr(data-price);
  font-size: 16px !important;
}
</style>
