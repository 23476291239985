<template>
  <div class="relative max-sm:pt-[23px]">
    <div
      ref="sl"
      class="keen-slider sl-slider mx-[-10px] py-15 max-sm:mx-[-5px]"
    >
      <div
        v-for="item in this.filteredData"
        :key="item.id"
        class="keen-slider__slide mb-12 product-slide flex flex-col justify-between px-20 max-sm:px-10"
      >
        <span
          v-if="!item.available"
          class="absolute left-[50%] top-0 z-10 w-full translate-x-[-50%] bg-ss3_light_blue px-5 py-5 text-center text-white"
          >In-Store Only
        </span>

        <a
          :href="item.url"
          class="group group relative mb-15 block transition-all duration-500 hover:scale-105"
        >
          <img
            :src="item.featured_image"
            :alt="item.title"
            class="w-full object-cover"
            :class="{
              'group-hover:opacity-0': item?.images?.length > 1,
            }"
          />
          <img
            v-if="item?.images?.length > 1"
            :src="item?.images[1]?.src"
            :alt="item.title"
            class="absolute left-0 top-0 h-full w-full object-cover opacity-0 group-hover:opacity-100"
          />
        </a>
        <junip
          :product-id="item?.id"
          :store-key="junipkey"
          :link="item?.url"
        ></junip>
        <a
          :href="item.url"
          class="my-[10px] block font-bionM hover:underline max-sm:mt-10 max-sm:leading-5 sm:text-xs"
        >
          {{ item?.title }}
        </a>
        <span class="mb-10 block flex-auto font-bionM">
          {{ item?.price }}
        </span>
        <form v-if="item.available" id="product-form">
          <AddToCartMisc v-slot="{loading, disabled, addToCart}">
            <button
              type="submit"
              @click.prevent="addToCart(item.variants[0].id)"
              class="btn-block max-md:h-30"
            >
              <span v-if="loading">Loading</span>
              <span v-else>
                <span class="hidden xs:block">Add To Cart</span>
                <span class="block xs:hidden">
                  <svg
                    class="svg-icon"
                    style="
                      width: 1em;
                      height: 1em;
                      vertical-align: middle;
                      fill: currentColor;
                      overflow: hidden;
                    "
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M849.066667 349.866667h-115.2c4.266667-25.6 0-51.2-8.533334-81.066667-34.133333-119.466667-157.866667-187.733333-277.333333-157.866667-55.466667 21.333333-102.4 59.733333-132.266667 110.933334-21.333333 38.4-29.866667 85.333333-29.866666 128H174.933333c-17.066667 0-29.866667 12.8-29.866666 34.133333v337.066667c0 115.2 93.866667 213.333333 213.333333 213.333333h200.533333c17.066667 0 34.133333-12.8 34.133334-34.133333s-12.8-34.133333-34.133334-34.133334H354.133333c-81.066667 0-149.333333-68.266667-149.333333-149.333333V413.866667H810.666667v174.933333c0 17.066667 12.8 34.133333 34.133333 34.133333s34.133333-12.8 34.133333-34.133333V384c0-21.333333-12.8-34.133333-29.866666-34.133333z m-179.2 0H354.133333c-4.266667-34.133333 0-68.266667 17.066667-98.133334C392.533333 213.333333 426.666667 187.733333 469.333333 174.933333c85.333333-25.6 174.933333 25.6 196.266667 110.933334 4.266667 21.333333 8.533333 42.666667 4.266667 64z"
                      fill="#080405"
                    />
                    <path
                      d="M849.066667 704h-132.266667v-132.266667c0-17.066667-12.8-29.866667-34.133333-29.866666-17.066667 0-29.866667 12.8-29.866667 34.133333v132.266667h-132.266667c-17.066667 0-29.866667 12.8-29.866666 34.133333 0 17.066667 12.8 29.866667 34.133333 29.866667h132.266667v132.266666c0 17.066667 12.8 29.866667 34.133333 29.866667 17.066667 0 29.866667-12.8 29.866667-34.133333V768H853.333333c17.066667 0 29.866667-12.8 29.866667-34.133333-4.266667-17.066667-17.066667-29.866667-34.133333-29.866667z"
                      fill="#1A1311"
                    />
                  </svg>
                </span>
              </span>
            </button>
          </AddToCartMisc>
        </form>
      </div>
      <p v-if="this.filteredData.length === 0">No products</p>
    </div>
    <!-- Slider Controllers -->
    <div
      class="mt-30 flex items-center justify-between gap-5 max-sm:pr-[31px]"
      v-if="filteredData.length > 4"
    >
      <button
        @click="
          () => {
            sl.prev();
          }
        "
      >
        <svg
          width="16"
          height="27"
          viewBox="0 0 16 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_60_93)">
            <path
              d="M13.5193 25.4783L2.48083 13.5L13.5193 1.52168"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_60_93">
              <rect
                width="16"
                height="27"
                fill="white"
                transform="translate(16 27) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>

      <!-- dots -->
      <div class="flex w-full justify-center">
        <div class="dots-gift flex flex-wrap justify-center gap-10">
          <button
            type="button"
            v-for="(_slide, idx) in dotHelper"
            @click="
              () => {
                sl.moveToIdx(idx);
              }
            "
            :class="{dot: true, active: current === idx}"
            :key="idx"
          ></button>
        </div>
      </div>

      <button
        @click="
          () => {
            sl.next();
          }
        "
      >
        <svg
          width="16"
          height="27"
          viewBox="0 0 16 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_60_91)">
            <path
              d="M2.48071 1.52173L13.5192 13.5L2.48071 25.4783"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_60_91">
              <rect width="16" height="27" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import KeenSlider from "keen-slider";
import "keen-slider/keen-slider.min.css";
import AddToCart from "../AddToCart";
import junip from "../Junip/index.vue";

import {mapState} from "vuex";
export default {
  name: "collection-filter",
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  components: {AddToCart, junip},

  data() {
    return {
      dataArr: JSON.parse(this.data),
      junipkey: window.__theme?.junip?.storekey || "CP9UUhju5nUNfaMofet11Bwo",
      filteredData: [],
      current: 0,
      sl: null,
    };
  },
  computed: {
    ...mapState("filter", ["filterBy", "sortBy", "priceFrom", "priceTo"]),
    dotHelper() {
      return this.sl
        ? [...Array(this.sl?.track?.details?.slides?.length).keys()]
        : [];
    },
  },

  watch: {
    sortBy() {
      this.applyFiltersAndSort();
    },
    filterBy: {
      handler(newFilterBy, oldFilterBy) {
        this.applyFiltersAndSort();
      },
      deep: true,
    },
    priceFrom() {
      this.applyFiltersAndSort();
    },
    priceTo() {
      this.applyFiltersAndSort();
    },
  },
  methods: {
    convertPrice(priceString) {
      return parseFloat(priceString.replace("$", ""));
    },
    applyFiltersAndSort() {
      let filtered = [...this.dataArr];
      let includeInStockFilter = false;
      let includeOutOfStockFilter = false;

      // stock filter
      includeInStockFilter = this.filterBy.includes("in_stock");

      includeOutOfStockFilter = this.filterBy.includes("out_of_stock");

      filtered = filtered.filter(item => {
        if (includeInStockFilter && includeOutOfStockFilter) {
          return true;
        } else if (includeInStockFilter) {
          return item.available;
        } else if (includeOutOfStockFilter) {
          return !item.available;
        } else {
          return true;
        }
      });

      // sort low to h...
      if (this.sortBy === "low_to_high") {
        filtered.sort(
          (a, b) => this.convertPrice(a.price) - this.convertPrice(b.price),
        );
      } else if (this.sortBy === "high_to_low") {
        filtered.sort(
          (a, b) => this.convertPrice(b.price) - this.convertPrice(a.price),
        );
      }
      // Apply price filtering only if priceFrom and priceTo are valid numbers
      if (
        this.priceFrom !== "" &&
        !isNaN(this.priceFrom) &&
        this.priceTo !== "" &&
        !isNaN(this.priceTo)
      ) {
        filtered = filtered.filter(item => {
          const price = this.convertPrice(item.price);
          return price >= this.priceFrom && price <= this.priceTo;
        });
      }

      this.filteredData = filtered;

      setTimeout(() => {
        this.sl = new KeenSlider(this.$refs.sl, {
          loop: true,
          rtl: false,
          initial: this.current,
          slideChanged: s => {
            this.current = s.track.details.rel;
          },
          breakpoints: {
            "(max-width: 1025px)": {
              slides: {
                perView: 4,
                spacing: 0,
              },
            },
            "(max-width: 767px)": {
              slides: {
                perView: 3,
                spacing: 0,
              },
            },
            "(max-width: 640px)": {
              slides: {
                perView: 1.5,
                spacing: 0,
              },
            },
          },
          slides: {
            perView: 4,
            spacing: 0,
          },
          slides: {
            perView: 4,
            spacing: 0,
          },
        });
      }, 1);
    },
  },
  mounted() {
    this.applyFiltersAndSort();
    // console.log(this.dataArr);
  },
  beforeDestroy() {
    if (this.sl) this.sl.destroy();
  },
};
</script>
<style>
.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #3f3f3f;
}

.dot:after {
  display: none !important;
}

.dot.active {
  background-color: #90c540;
  border-color: transparent;
}
</style>
