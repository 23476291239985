<template>
  <div class="relative max-sm:px-25">
    <div ref="sl" class="keen-slider sl-slider">
      <div
        v-for="(it, k) in this.dataArr"
        :key="k"
        class="keen-slider__slide flex flex-col bg-pastelPinkL text-white"
      >
        <div class="relative pb-[80%]">
          <img
            v-if="it.image"
            :src="it.image"
            :alt="it.title"
            class="absolute left-0 top-0 h-full w-full object-cover"
          />
        </div>
        <div class="px-[30px] py-[35px] text-center max-sm:py-20">
          <span class="mb-10 flex justify-center gap-[3px]">
            <svg
              v-for="i in 5"
              :key="i"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 19 17"
              fill="none"
            >
              <path
                d="M9.25858 0L11.9415 5.56584L18.064 6.39752L13.5997 10.6691L14.7006 16.7489L9.25858 13.8231L3.81652 16.7489L4.9175 10.6691L0.453147 6.39752L6.57565 5.56584L9.25858 0Z"
                fill="white"
              />
            </svg>
          </span>
          <h3
            class="mb-10 font-bionM text-xl max-sm:text-base"
            v-html="it.title"
          ></h3>
          <div class="mb-20 max-sm:text-sm" v-html="it.text"></div>
          <span
            class="font-semibold max-sm:text-sm"
            v-html="it.customer"
          ></span>
        </div>
      </div>
    </div>
    <!-- Slider Controllers -->
    <div
      class="mt-30 flex items-center justify-between gap-5"
      :class="{'sm:hidden': !controls}"
    >
      <button
        @click="
          () => {
            slider.prev();
          }
        "
      >
        <svg
          width="16"
          height="27"
          viewBox="0 0 16 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_60_93)">
            <path
              d="M13.5193 25.4783L2.48083 13.5L13.5193 1.52168"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_60_93">
              <rect
                width="16"
                height="27"
                fill="white"
                transform="translate(16 27) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>

      <!-- dots -->
      <div class="flex w-full justify-center">
        <div
          v-if="slider"
          class="dots-gift flex flex-wrap justify-center gap-10"
        >
          <button
            type="button"
            v-for="(_slide, idx) in dotHelper.slice(0, -1)"
            @click="
              () => {
                slider.moveToIdx(idx);
              }
            "
            :class="{dot: true, active: current === idx}"
            :key="idx"
          ></button>
        </div>
      </div>

      <button
        @click="
          () => {
            slider.next();
          }
        "
      >
        <svg
          width="16"
          height="27"
          viewBox="0 0 16 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_60_91)">
            <path
              d="M2.48071 1.52173L13.5192 13.5L2.48071 25.4783"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_60_91">
              <rect width="16" height="27" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useKeenSlider} from "keen-slider/vue.es";

export default {
  name: "slider-reviews",
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      dataArr: JSON.parse(this.data),
    };
  },
  setup() {
    const current = ref(0);
    const [sl, slider] = useKeenSlider({
      loop: true,
      breakpoints: {
        "(max-width: 1024px)": {
          slides: {
            perView: 2,
            spacing: 10,
          },
        },
        "(max-width: 600px)": {
          slides: {
            perView: 1,
            spacing: 0,
          },
        },
      },

      slides: {
        perView: 3,
        spacing: 30,
      },
      slideChanged: s => {
        current.value = s.track.details.rel;
      },
    });
    const dotHelper = computed(() =>
      slider.value
        ? [...Array(slider.value.track.details.slides.length).keys()]
        : [],
    );
    return {sl, current, dotHelper, slider};
  },
};
</script>
